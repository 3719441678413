.home {
	height: 100%;
	position: relative;

	.container {
		width: 100%;

		.top__content {
			margin-top: 0.45rem;
			width: 100%;
			@include flexrc;
			justify-content: space-between;
		}

		.bunny__container {
			.grandprize {
				position: absolute;
				top: 2.1rem;
				left: 2.3rem;
				width: 3.1rem;
				height: 2.3rem;
				@include bgv('landing-kv');
				background-size: contain !important;
			}
		}

		.bottom__content {
			position: relative;
			margin-top: 0.2rem;

			.btn-lucky {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				width: 1.85rem;
				height: 0.44rem;
				@include bgv("btn-lucky-spin");

				>div {
					@include flexrc;

					.ico-lucky {
						@include bgv("ico-lucky-spin");
						width: 0.23rem;
						height: 0.23rem;
						margin-right: 0.1rem;
					}

					span {
						font-size: 0.24rem;
						line-height: 0.24rem;
						font-weight: 600;
						color: #000;
					}
				}
			}

			.btn-grey {
				position: absolute;
				right: (3.05rem / 2);
				transform: translateX(50%);
				width: 1.85rem;
				height: 0.42rem;
				@include bgv("btn-active");
				font-size: 0.24rem;
				line-height: 0.44rem;
				font-weight: 600;
				color: #454b5a;
			}
		}
	}
}