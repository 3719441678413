.modal-reward {
	.modal__container {
		.modal__content {
			@include flexcc;
			justify-content: flex-start;

			.text__head {
				font-size: 0.56rem;
				font-weight: 700;
				color: #ffe841;
				line-height: 0.63rem;
				text-transform: uppercase;
				margin: auto 0 0;
			}

			.text__desc {
				width: 72%;
				margin: 0 auto 0.16rem;
				font-size: 0.18rem;
				color: rgba(255, 255, 255, 0.9);
				line-height: 0.25rem;
				text-align: center;
				text-transform: uppercase;
			}

			.item {
				margin: 0.1rem 0 0.4rem;

				.item__frame {
					width: 1.1rem;
					height: 1.1rem;
					margin: 0 auto;
					@include flexcc;

					&.token-type {
						border: 0.02rem solid #ddd064;
						position: relative;
						box-shadow: 0rem 0rem 0.3rem 0.04rem rgba(209, 177, 22, 0.5) inset,
							0rem 0rem 0.08rem 0.04rem rgba(209, 177, 22, 0.5) inset;
					}

					&.common {
						@include bgv("rarity-common");
					}

					&.rare {
						@include bgv("rarity-rare");
					}

					&.uncommon {
						@include bgv("rarity-uncommon");
					}

					&.epic {
						@include bgv("rarity-epic");
					}

					&.legendary {
						@include bgv("rarity-legend");
					}

					&.item-type {
						.item__img {
							position: relative;
							top: -8%;
						}
					}

					.item__img {
						@include flexrc;
						width: 100%;
						height: 100%;

						&.hygiene {
							width: 75%;
							height: 75%;
							@include bgv("token-hygiene");
						}

						&.food {
							width: 75%;
							height: 75%;
							@include bgv("token-food");
						}

						&.water {
							width: 75%;
							height: 75%;
							@include bgv("token-water");
						}

						img {
							width: 90%;
							max-width: 100%;
						}
					}
				}

				.item__name {
					font-size: 0.18rem;
					text-transform: uppercase;
					color: #fff;
					line-height: 0.22rem;
					text-align: center;
					margin: 0.08rem 0;
				}
			}

			.btn-yellow {
				margin: 0 auto 0.34rem;
				width: 1.85rem;
				height: 0.44rem;
				font-size: 0.26rem;
				line-height: 0.44rem;
				font-weight: 600;
				color: #454b5a;
				@include bgv("btn-yellow");
			}
		}
	}
}