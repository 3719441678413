@keyframes scaleY {
	0% {
		opacity: 0;
		transform: scale3d(1, 0, 1);
	}
	100% {
		transform: scale3d(1, 1, 1);
		opacity: 1;
	}
}

@keyframes scaleYreverse {
	0% {
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
	100% {
		opacity: 0;
		transform: scale3d(1, 0, 1);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		visibility: hidden;
	}
	100% {
		visibility: visible;
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
		visibility: visible;
	}
	100% {
		visibility: hidden;
		opacity: 0;
	}
}

@keyframes glowing {
	0% {
		filter: drop-shadow(0rem 0rem 0.02rem #ffff);
	}
	50% {
		filter: drop-shadow(0rem 0rem 0.15rem #ffff);
	}
	100% {
		filter: drop-shadow(0rem 0rem 0.02rem #ffff);
	}
}

@keyframes glowingYellow {
	0% {
		filter: drop-shadow(0rem 0rem 0.02rem yellow);
	}
	50% {
		filter: drop-shadow(0rem 0rem 0.15rem yellow);
	}
	100% {
		filter: drop-shadow(0rem 0rem 0.02rem yellow);
	}
}

.glowing {
	animation-name: glowing;
}

.glowingYellow {
	animation-name: glowingYellow;
}

.fadeIn {
	animation-name: fadeIn;
}

.fadeOut {
	animation-name: fadeOut;
}

.scaleY {
	animation-name: scaleY;
}

.scaleYreverse {
	animation-name: scaleYreverse;
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both;
}
.animated.slow {
	animation-duration: 2s;
}
.animated.faster {
	animation-duration: 250ms;
}
.animated.slower {
	animation-duration: 10s;
	animation-timing-function: linear;
}
.animated.infinite {
	animation-iteration-count: infinite;
}
