.modal-confirm {
	.modal__container {
		.modal__content {
			@include flexcc;
			justify-content: flex-start;

			.text__desc {
				width: 72%;
				margin: auto auto 0.16rem;
				font-size: 0.18rem;
				color: rgba(255, 255, 255, 0.9);
				line-height: 0.25rem;
				text-align: center;
			}

			.item {
				.item__frame {
					border: 0.02rem solid #ddd064;
					width: 1rem;
					height: 1rem;
					margin: 0 auto;

					&.token__frame {
						position: relative;
						box-shadow: 0rem 0rem 0.3rem 0.04rem rgba(209, 177, 22, 0.5) inset,
							0rem 0rem 0.08rem 0.04rem rgba(209, 177, 22, 0.5) inset;
					}

					.item__img {
						@include flexrc;
						width: 100%;
						height: 100%;
						background-size: 75% !important;

						&.hygiene {
							@include bgv("token-hygiene");
						}

						&.food {
							@include bgv("token-food");
						}

						&.water {
							@include bgv("token-water");
						}
					}
				}

				.item__name {
					font-size: 0.18rem;
					text-transform: uppercase;
					color: #fff;
					line-height: 0.22rem;
					text-align: center;
					margin: 0.08rem 0;
				}
			}

			.counter {
				@include flexrc;
				margin: 0rem 0 0.2rem;

				.btn {
					width: 0.44rem;
					height: 0.44rem;

					&.btn-plus {
						@include bgv("ico-plus");
					}

					&.btn-minus {
						@include bgv("ico-minus");
					}
				}

				.number {
					width: 0.81rem;
					height: 0.44rem;
					line-height: 0.44rem;
					font-size: 0.27rem;
					border: 0.02rem solid #fff;
					margin: 0 0.1rem;
					text-align: center;
					color: #fff;
				}
			}

			.btn-yellow {
				margin: 0 auto 0.34rem;
				width: 1.85rem;
				height: 0.44rem;
				font-size: 0.26rem;
				line-height: 0.44rem;
				font-weight: 600;
				color: #454b5a;
				@include bgv("btn-yellow");
			}
		}
	}
}