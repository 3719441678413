.header {
	padding: 2% 4% 0;
	position: relative;

	.container {
		width: 100%;
		display: flex;
		justify-content: space-between;

		.group {
			display: flex;

			.btn-info {
				width: 0.46rem;
				height: 0.46rem;
				margin-right: 0.06rem;
				@include bgv("btn-info");
			}

			.btn-bgm {
				width: 0.46rem;
				height: 0.46rem;
				margin-left: 0.06rem;
				transition: background-image 0.5s ease-in-out;

				&.bgm-off {
					@include bgv("ico-sound-on");
				}

				&.bgm-on {
					@include bgv("ico-sound-off");
				}
			}
		}

		.title {
			width: 5.5rem;
			height: 1.2rem;
			position: absolute;
			left: 50%;
			top: 20%;
			@include bgv("title");
			transform: translateX(-50%);
			background-size: contain !important;
		}

		.balance {
			display: inline-block;
			width: 2.85rem;
			height: 0.45rem;
			@include bgv("bg-bar-icon");
			@include flexrc;

			.tokens {
				width: (2.85rem / 3);
				cursor: pointer;

				>div {
					@include flexrc;

					.ico-token {
						width: 0.24rem;
						height: 0.24rem;
						margin-right: 0.02rem;

						&.hygiene__token {
							@include bgv("token-hygiene");
						}

						&.food__token {
							@include bgv("token-food");
						}

						&.water__token {
							@include bgv("token-water");
						}
					}

					span {
						font-size: 0.24rem;
						color: #fff;
						width: 3ch;
						text-align: right;
					}
				}
			}
		}
	}
}