.toast__container {
	.toast__content {
		@include flexcc;

		&.error {
			text-align: center;
			font-weight: 400;
			color: #d6d6d6;
			max-width: 100%;
			font-size: 0.3rem;
			line-height: 0.34rem;
			text-transform: uppercase;
			letter-spacing: -0.02rem;
			padding: 0 0.7rem 0.11rem;
		}

		.text__head {
			margin-top: auto;
			font-size: 0.56rem;
			font-weight: 600;
			color: #ffe841;
			line-height: 0.63rem;
			text-transform: uppercase;
			margin-bottom: 0.02rem;
			text-align: center;
			padding: 0 0.5rem;
		}

		.text__desc {
			font-size: 0.19rem;
			font-weight: 800;
			color: rgba(255, 255, 255, 0.9);
			line-height: 0.25rem;
			text-align: center;
			margin-bottom: 0.16rem;
			padding: 0 0.5rem;
		}

		.btn-yellow {
			margin: auto auto 0.34rem;
			width: 1.85rem;
			height: 0.44rem;
			font-size: 0.24rem;
			line-height: 0.44rem;
			font-weight: 600;
			color: #454b5a;
			@include bgv("btn-yellow");
		}
	}
}