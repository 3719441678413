.modal-history {
	.modal__container {
		.modal__content {
			@include flexcc;
			.tab__header {
				@include flexrc;
				margin: 0.3rem 0 0;
				.tab {
					width: 1.61rem;
					height: 0.22rem;
					@include bgv("popup-bg-tab-off");
					text-align: center;
					font-weight: 400;
					margin: 0 -0.05rem;
					text-transform: capitalize;
					cursor: pointer;
					position: relative;
					line-height: 0.22rem;
					span {
						font-size: 0.18rem;
						line-height: 0.22rem;
						color: #000000;
						position: relative;
						top: 0.01rem;
					}
					.mark {
						position: absolute;
						top: 0;
						right: 0%;
						width: 0.23rem;
						height: 0.23rem;
						transform: translateY(-50%);
						@include bgc;
						@include bgv("ico-warning");
					}
					&.active {
						@include bgv("popup-bg-tab-on");
					}
				}
			}
			.tab__content {
				margin-top: 0.1rem;
				margin-bottom: auto;
				width: 100%;
				height: calc(100% - 0.62rem);
				padding: 0.13rem 0.3rem 0.23rem;

				.history__container {
					width: 94%;
					height: calc(100% - 0.4rem);
					margin: 0.2rem auto auto;
					@include scrollbar(
						#ffe940,
						rgba(255, 233, 64, 0.25098039215686274),
						0rem
					);
					max-height: 100%;
					overflow-y: auto;
					.table__wrapper {
						padding: 0 0.8rem 0 0.7rem;
						table,
						td,
						th {
							border: 0.02rem solid #fff;
						}
						table {
							width: 70%;
							height: 100%;
							margin: 0 auto;
							border-collapse: collapse;
							thead {
								th {
									padding: 0.12rem 0;
									font-size: 0.23rem;
									line-height: 0.28rem;
									font-size: 800;
									color: #fff;
									text-transform: uppercase;
									text-align: center;
									color: #fce84d;
									border-left: 0.02rem solid #fce84d;
									&:nth-child(2) {
										border-left: #fff;
									}
								}
							}
							tbody {
								td {
									padding: 0.1rem 0;
									font-size: 0.2rem;
									line-height: 0.24rem;
									font-weight: 400;
									text-transform: uppercase;
									text-align: center;
									color: #fff;
									border-left: none;
									border-right: none;
								}
							}
						}
					}
				}
			}
		}
	}
}
