.wheel {
	position: relative;
	width: 4.51rem;
	height: 4.51rem;

	.arrow {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-47%) translateY(-56%);
		width: 1.85rem;
		height: 2.1rem;
		@include bgv("spin-pointer");
		z-index: 3;
	}
	.wheel__rotation {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		will-change: transform;

		@for $i from 1 through 12 {
			&.reward-#{$i} {
				$rot: 360 / 12;
				$deg: ($i * $rot) - ($rot / 2);
				$turn: $deg / 360;
				@keyframes rotating-#{$i} {
					from {
						transform: rotate(0);
					}
					to {
						transform: rotate(-#{$turn + 2}turn);
					}
				}
				&.last-state {
					transform: rotate(-#{$turn + 2}turn);
				}
				&.animated {
					animation: 5s
						cubic-bezier(0.165, 0.84, 0.44, 1)
						0s
						1
						normal
						rotating-#{$i}
						forwards;
				}
			}
		}

		@include bgv("spin-wheel");

		.wheel__content {
			width: 100%;
			height: 100%;
			transform: rotate(-96deg);
			.item {
				$rot: 360 / 24;
				width: 0.48rem;
				height: 0.48rem;
				// width: 0.01rem;
				// height: 1.6rem;
				// height: 50%;
				// background-color: black;
				position: absolute;
				left: calc((4.51rem / 2) - (0.48rem / 2));
				top: calc((4.51rem / 2) - (0.48rem / 2));
				@for $i from 1 through 24 {
					&.order-#{$i} {
						$deg: ($i * $rot) - $rot;
						$r: 30%;
						$x: cos($deg) * $r;
						$y: sin($deg) * $r;
						// transform: translate(#{$x + "rem"}, #{$y + "rem"})
						// 	rotate(#{90 + $deg}deg);
					}
				}

				.item__frame {
					width: 100%;
					height: 100%;
					@include flexcc;
					&.common {
						@include bgv("rarity-common");
					}
					&.rare {
						@include bgv("rarity-rare");
					}
					&.uncommon {
						@include bgv("rarity-uncommon");
					}
					&.epic {
						@include bgv("rarity-epic");
					}
					&.legendary {
						@include bgv("rarity-legend");
					}
					.item__img {
						width: 100%;
						@include flexcc;
						img {
							width: 90%;
							border-style: none;
							outline: none;
							max-width: 100%;
						}
					}
				}
			}
		}
	}
}
