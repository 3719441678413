.tippy-box {
	min-width: 0.09rem;
	max-width: 1.2rem;
	min-height: 0.34rem;
	border: 1px solid #ffe841;
	background: rgba(0, 0, 0, 0.75) !important;
	z-index: 333;
	@include flexcc;

	.tippy-content {
		// line-height: 0.16rem;
		// padding: 0.05rem 0.15rem 0.05rem 0.15rem;

		.tippy-text {
			font-size: 0.12rem;
			text-align: center;
			color: #fff;
			text-transform: uppercase;
		}
	}

	.tippy-arrow {
		// top: 50% !important;
		// transform: translateY(-50%) !important;
	}
}