/* SETUP FOR KEEP ASPECT RATIO VIEWPORT */
#root {
	position: absolute;
	width: 100%;
	height: 100%;
	font-size: 0.36rem;
}

#Main {
	position: fixed;
	margin: 0 auto;
	// top: 0 !important;
	right: 0;
	left: 0;
	// bottom: 0;
	/* setup layout size */
	width: 13.34rem;
	height: 6.6rem;

	&:before {
		// content: '';
		position: fixed;
		z-index: -2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #000;
		background-size: 13.34rem 6.6rem !important;
		@include bgv("bg");
	}

	@import "./component/header";

	.content-container {
		width: 100%;
		height: calc(91.2% - 0.6rem);
		padding: 0 4%;
		position: relative;
		@import "./pages/home";
	}
}

/* Modal layout setup */
.react-responsive-modal-root {
	display: flex;
	align-items: center;
	width: 100%;
	// height: 100%;
	height: 6.6rem;
}

.react-responsive-modal-overlay {
	// overflow: hidden;
	overflow: hidden;
	padding: unset !important;
	width: 100%;
	height: 6.6rem;
	align-items: center !important;
}

.react-responsive-modal-container {
	// width: 13.34rem !important;
	// height: 6.6rem !important;
	display: flex;
	align-items: center;
	margin: 0 auto;
	font-size: 0.36rem;
	overflow: visible;
}

.react-responsive-modal-modal {
	margin: 0 auto !important;
	max-width: none !important;
	padding: 0;
	background: none;
	box-shadow: none;
	overflow: visible !important;
	@include bgv("popup-bg");
	width: 8.75rem;
	height: 4.47rem;

	.modal__container {
		height: 100%;

		.modal__title {
			height: 0.4rem;

			.modal__logo {
				position: absolute;
				left: 0;
				top: 0;
				transform: translate(-14%, -17%);
				width: 0.58rem;
				height: 0.57rem;
				@include bgv("popup-animated");
			}

			h3 {
				position: absolute;
				left: 0.54rem;
				top: 0;
				letter-spacing: 0rem;
				color: #ffffff;
				text-transform: uppercase;
				font-size: 0.25rem;
				line-height: 0.3rem;
				font-weight: 600;
				text-align: center;
			}
		}

		.modal__content {
			height: calc(100% - 0.4rem);
			padding-bottom: 0.11rem;
		}
	}
}

.modal__overlay,
.toast__overlay {
	@include bgv("popup-bg-overlay");
	// width: 13.36rem;
	// height: 6.6rem;
	// position: absolute;
	width: 100%;
	height: 100%;
	margin: auto;
}

.btn-close {
	cursor: pointer;
	display: block;
	top: 0%;
	right: 0%;
	transform: translate(-11%, 5%);
	outline: none !important;
	border: none !important;
	width: 0.36rem;
	height: 0.36rem;

	&:hover {
		filter: brightness(1.2);
	}

	&.close-lucky {
		transform: translate(-20.4%, 128%);
	}

	.ico-close {
		width: 100%;
		height: 100%;
		@include bgv("popup-close");
	}
}

/* END SETUP FOR KEEP ASPECT RATIO VIEWPORT */

.btn {
	padding: 0;
	outline: none;
	border: none;
	text-transform: uppercase;
	font-weight: 600;
	cursor: pointer;
	user-select: none;
	background-color: transparent;

	&:disabled {
		cursor: default;
		filter: grayscale(1) !important;
	}

	&.disabled {
		cursor: default;
		filter: grayscale(1) !important;
	}
}

button {
	&:disabled {
		cursor: default;
		filter: grayscale(1) !important;
	}
}
