.level-up__container {
	@include bgv("bg-level");
	width: 2.07rem;
	height: 4.42rem;

	.milestone {
		position: relative;
		margin-top: 0.65rem;
		margin-left: 0.13rem;

		.bar {
			position: absolute;

			.level {
				position: relative;
				width: 0.21rem;
				height: 0.74rem;
				margin-bottom: -0.04rem;
				@include bgv("bg-level-empty");

				&.fill {
					@include bgv("bg-level-fill");

					.item {
						filter: none !important;

						.item__frame {
							position: relative;
							pointer-events: none;
						}
					}
				}

				&.claimed {
					@include bgv("bg-level-fill");

					.item {
						filter: grayscale(0);

						.item__frame {
							position: relative;

							.item__overlay {
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								background: rgba(0, 0, 0, 0.7);
								z-index: 3;
							}

							>div {
								&::before {
									position: absolute;
									content: " ";
									left: 50%;
									top: 50%;
									transform: translateX(-50%) translateY(-50%);
									width: 0.36rem;
									height: 0.21rem;
									@include bgv("checklist");
									z-index: 33;
								}
							}
						}
					}
				}

				.item {
					position: absolute;
					left: 1.15rem;
					top: 50%;
					transform: translateY(-50%);
					filter: grayscale(1);
					cursor: pointer;

					.item__frame {
						width: 0.54rem;
						height: 0.54rem;

						&.common {
							@include bgv("rarity-common");
						}

						&.rare {
							@include bgv("rarity-rare");
						}

						&.uncommon {
							@include bgv("rarity-uncommon");
						}

						&.epic {
							@include bgv("rarity-epic");
						}

						&.legend {
							@include bgv("rarity-legend");
						}

						>div {
							width: 100%;
							height: 100%;
							@include flexcc;

							.item__img {
								width: 100%;
								@include flexcc;
								position: relative;
								top: -8%;

								img {
									width: 95%;
									max-width: 100%;
								}
							}
						}
					}
				}

				>span {
					position: absolute;
					left: 0.26rem;
					top: 50%;
					transform: translateY(-50%);
					color: #a6acb3;
					font-size: 0.24rem;
					line-height: 0.28rem;
					font-weight: 600;
					white-space: nowrap;
					text-transform: uppercase;
				}
			}
		}
	}
}