.modal-lucky {
	width: 13.36rem;
	height: 6.6rem;
	background: none;
	.modal__container {
		height: 100%;
		.modal__content {
			height: 100%;
			@include flexrc;
			justify-content: space-around;
			.kv {
				width: 4.08rem;
				height: 5.64rem;
				@include bgv("spin-kv");
			}
			.wheel__container {
				.group {
					position: relative;
					z-index: 3;
					@include flexrc;
					margin-top: 0.4rem;
					.btn-spin {
						width: 1.85rem;
						height: 0.44rem;
						font-size: 0.24rem;
						line-height: 0.44rem;
						font-weight: 600;
						color: #85783f;
						@include bgv("btn-yellow");
						margin-right: 0.08rem;
					}
					.btn-history {
						margin-left: 0.08rem;
						width: 0.46rem;
						height: 0.46rem;
						@include bgv("ico-history");
					}
				}
			}
			.leaderboard__container {
				.leaderboard {
					width: 4.1rem;
					height: 5.51rem;
					@include bgv("spin-board");
					.leaderboard__title {
						font-size: 0.24rem;
						line-height: 0.28rem;
						color: #fff;
						text-transform: uppercase;
						text-align: center;
					}
					.leaderboard__table {
						width: 91%;
						height: calc(100% - 0.68rem);
						margin: 0.2rem 0.13rem 0 auto;
						@include scrollbar(
							#ffe940,
							rgba(255, 233, 64, 0.25098039215686274),
							0rem
						);
						max-height: 100%;
						overflow-y: auto;
						.table__wrapper {
							padding-right: 0.13rem;
							table,
							td,
							th {
								border: 0.02rem solid #fff;
							}
							table {
								width: 100%;
								height: 100%;
								border-collapse: collapse;
								thead {
									th {
										padding: 0.14rem 0;
										font-size: 0.26rem;
										line-height: 0.3rem;
										font-size: 800;
										color: #fff;
										text-transform: uppercase;
										text-align: center;
										color: #fce84d;
										border-left: 0.02rem solid #fce84d;
										&:nth-child(2) {
											border-left: #fff;
										}
									}
								}
								tbody {
									td {
										padding: 0.1rem 0.05rem;
										font-size: 0.2rem;
										line-height: 0.28rem;
										font-weight: 400;
										text-transform: uppercase;
										text-align: center;
										color: #fff;
										border-left: none;
										border-right: none;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.overlay-lucky {
	// @include bgv("popup-bg-overlay");
	background: rgba(0,0,0,.8);
	// width: 13.36rem;
	// height: 6.6rem;
	// position: absolute;
	width: 100%;
	height: 100%;
	margin: auto;
}
