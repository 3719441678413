.bunny {
	position: absolute;
	left: 50%;
	bottom: 0;
	transform: translate(-50%, 0);
	width: 7rem;
	height: 5.4rem;
	z-index: -1;
	background-size: contain !important;
	@include bgv("animal-static");

	&.water,
	&.food,
	&.hygiene {
		background: none;
	}

	img {
		width: 100%;
		max-width: 100%;
		border-style: none;
		outline: none;
	}
}