.modal-info {
	.modal__container {
		.modal__content {
			@include flexcc;
			.tab__header {
				@include flexrc;
				margin: 0.3rem 0 0;
				.tab {
					width: 1.61rem;
					height: 0.22rem;
					@include bgv("popup-bg-tab-off");
					text-align: center;
					font-weight: 400;
					margin: 0 -0.05rem;
					text-transform: capitalize;
					cursor: pointer;
					position: relative;
					line-height: 0.22rem;
					span {
						font-size: 0.18rem;
						line-height: 0.22rem;
						color: #000000;
						position: relative;
						top: 0.01rem;
					}
					.mark {
						position: absolute;
						top: 0;
						right: 0%;
						width: 0.23rem;
						height: 0.23rem;
						transform: translateY(-50%);
						@include bgc;
						@include bgv("ico-warning");
					}
					&.active {
						@include bgv("popup-bg-tab-on");
					}
				}
			}
			.tab__content {
				margin-top: 0.1rem;
				margin-bottom: auto;
				width: 100%;
				height: calc(100% - 0.62rem);
				padding: 0.13rem 0.3rem 0.23rem;
				.carousel__container {
					width: 100%;
					height: 95%;
					margin: 0 auto;
					position: relative;
					.slick-slider {
						width: 6.67rem;
						height: 100%;
						margin: 0 auto;
						.slick-list {
							width: 100%;
							height: 100%;
							overflow: hidden;
							.slick-track {
								height: 100%;
								display: flex !important;
								.slick-slide {
									position: relative;
									outline: none !important;
									border: none !important;
									height: inherit !important;
									@include flexcc;
									overflow: hidden;
									> div {
										width: 100%;
										.sample-content {
											height: 2.74rem;
											@include bgc;
											img {
												width: 100%;
												height: 100%;
											}
										}
									}
								}
							}
						}
					}
					.slick-dots {
						z-index: 999;
						display: flex !important;
						justify-content: center;
						align-items: center;
						list-style-type: none;
						padding: 0;
						> li {
							width: 0.15rem;
							height: 0.15rem;
							margin: 0 0.05rem;
						}
						.slick-dot {
							transition: background-image 250ms ease-in-out;
							@include bgi("icon_dot_inactive.png");
							@include bgc;
							width: 100%;
							height: 100%;
							&.active {
								@include bgi("icon_dot_active.png");
							}
						}
					}
					.ico-arrow {
						position: absolute;
						top: 50%;
						cursor: pointer;
						width: 0.37rem;
						height: 0.44rem;
						z-index: 33;

						&.btn-next {
							@include bgv("popup-arrow");
							right: 0.2rem;
							transform: translate(0, -50%) rotate(180deg);
						}
						&.btn-prev {
							@include bgv("popup-arrow");
							left: 0.2rem;
							transform: translate(0, -50%);
						}
					}
				}

				.rules__container {
					width: 100%;
					height: 100%;
					padding: 0.13rem 0.15rem 0.27rem 0.25rem;
					.rules__wrapper {
						max-height: 100%;
						overflow-y: scroll;
						@include scrollbar(
							#ffe940,
							rgba(255, 233, 64, 0.25098039215686274),
							0rem
						);
						.text {
							padding-right: 0.3rem;
							color: #ffffff;
							font-size: 0.19rem;
							line-height: 0.3rem;
						}
					}
				}

				.history__container {
					width: 94%;
					height: calc(100% - 0.4rem);
					margin: 0.2rem auto auto;
					@include scrollbar(
						#ffe940,
						rgba(255, 233, 64, 0.25098039215686274),
						0rem
					);
					max-height: 100%;
					overflow-y: auto;
					.table__wrapper {
						padding: 0 0.8rem 0 0.7rem;
						table,
						td,
						th {
							border: 0.02rem solid #fff;
						}
						table {
							width: 100%;
							height: 100%;
							border-collapse: collapse;
							thead {
								th {
									padding: 0.12rem 0;
									font-size: 0.23rem;
									line-height: 0.28rem;
									font-size: 800;
									color: #fff;
									text-transform: uppercase;
									text-align: center;
									color: #fce84d;
									border-left: 0.02rem solid #fce84d;
									&:nth-child(2) {
										border-left: #fff;
									}
								}
							}
							tbody {
								td {
									padding: 0.1rem 0;
									font-size: 0.2rem;
									line-height: 0.24rem;
									font-weight: 400;
									text-transform: uppercase;
									text-align: center;
									color: #fff;
									border-left: none;
									border-right: none;
								}
							}
						}
					}
				}
			}
		}
	}
}
