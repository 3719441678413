.modal-policy {
	overflow: hidden;

	.modal__container {
		.modal__content {

			// @include flexcc;
			.policy-content {
				width: 100%;
				height: 83%;
				font-family: inherit !important;
				padding: 0 0.3rem;

				.title-text {
					font-size: 0.2rem;
					line-height: 0.28rem;
					color: #fff;
					padding: 0.08rem 0;
					text-align: center;

					p:nth-child(1) {
						font-size: 0.3rem;
						line-height: 0.34rem;
						margin-bottom: 0.1rem;
					}
				}

				.text__wrapper {
					padding: 0.2rem 0.3rem;
					height: 64%;
					background: rgba(255, 255, 255, 0.1);
					overflow-y: auto;
					@include scrollbar(#ffe940,
						rgba(255, 233, 64, 0.25098039215686274),
						0rem);

					.text {
						font-size: 0.2rem;
						color: #fff;

						p {
							font-size: 0.2rem;
							line-height: 0.34rem;
						}
					}
				}
			}

			.group {
				@include flexrc;
				height: 16%;
				width: 100%;

				// background-color: #f9ebcf;
				.btn {
					width: 1.85rem;
					height: 0.44rem;
					font-size: 0.3rem;
					line-height: 0.44rem;
					@include bgc;
					@include bgv("btn-yellow");
					color: rgba(0, 0, 0, 0.749);
					margin: 0 0.13rem;
				}

				.btn-reject {
					@include bgv("btn-grey");
				}
			}
		}
	}
}