.care-kit__container {
	width: 3.05rem;
	height: 4.42rem;
	@include bgv("bg-carekit");
	position: relative;

	.foods {
		margin-top: 0.63rem;
		@include flexcc;

		.food__container {
			width: 2.76rem;
			height: 0.67rem;
			@include bgv("bg-carekit-progress-cont");
			margin-bottom: 0.1rem;
			position: relative;
			cursor: pointer;

			.milestone {
				position: absolute;
				left: 60%;
				top: 50%;
				transform: translate(-50%, -50%);
				text-align: center;

				.points {
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					font-size: 0.24rem;
					line-height: 0.36rem;
					color: #fff;
				}

				.bar {
					@include bgv("bg-carekit-progress");
					width: 2.02rem;
					height: 0.36rem;
					padding: 0.025rem 0.04rem;
					overflow: hidden;

					.bar__inner {
						width: 0%;
						height: 100%;
						@include bgv("carekit-progress-fill");
						background-repeat: repeat-x !important;
						transition: width 1s ease-in-out;
						transition-delay: 0.25s;
					}
				}
			}

			.ico-kit {
				position: absolute;
				left: 12.5%;
				top: 50%;
				transform: translate(-50%, -50%);

				&.food {
					width: 0.5rem;
					height: 0.36rem;
					@include bgv("ico-food");
				}

				&.water {
					width: 0.33rem;
					height: 0.5rem;
					@include bgv("ico-water");
				}

				&.hygiene {
					width: 0.42rem;
					height: 0.47rem;
					@include bgv("ico-hygiene");
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.description {
		padding: 0.08rem 20%;

		p {
			color: #fff;
			font-size: 0.24rem;
			line-height: 0.24rem;
			font-weight: 600;
			text-transform: uppercase;
			text-align: center;
		}
	}

	.btn-feed {
		position: relative;
		width: 1.85rem;
		height: 0.42rem;
		@include bgv("btn-active");
		top: 0.26rem;
		left: 50%;
		transform: translateX(-50%);
		text-transform: uppercase;
		color: #454b5a;
		font-size: 0.24rem;
		line-height: 0.42rem;

		.daily {
			position: absolute;
			top: 0;
			left: 0;
			transform: translate(-50%, -50%);
			width: 0.67rem;
			height: 0.19rem;
			@include bgv("btn-free-daily");
			font-size: 0.14rem;
			line-height: 0.19rem;
			color: #454b5a;
		}
	}
}