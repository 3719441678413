.loading {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	overflow: hidden;
	place-items: center;
	display: grid;
	&.none {
		display: none;
	}

	.wp_tips {
		width: 60%;
		font-size: 2vw;
		color: #ffffff;
		text-align: center;
	}
	.wp_content {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.img {
		position: relative;
		width: 2rem;
		height: 2rem;
	}
}
