@import "variable";
@import "font";
@import "reset";
@import "mixins";
@import "functions";
@import "lang";
@import "animate";
@import "math";
/* setup for keep aspect ratio viewport*/
@import "layout";

.gunar {
	font-family: $gnr-font-family;
}

.refrigerator {
	font-family: $rfg-font-family;
}

.myraid {
	font-family: $myrd-font-family;
}

html {
	width: 100%;
	height: 100%;
	font-family: $gnr-font-family;
}

body {
	width: 100%;
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #000;
	@include bgv("bg");
	background-size: cover !important;
}

/* FIXED FLICKER MODAL */
.react-responsive-modal-overlay,
.react-responsive-modal-container,
.react-responsive-modal-modal,
.react-responsive-modal-root {
	animation-fill-mode: forwards !important;
	top: 0 !important;
}

@import "./component/popup/lucky";
@import "./component/popup/info";
@import "./component/popup/share";
@import "./component/popup/policy";
@import "./component/popup/history";
@import "./component/popup/confirm";
@import "./component/popup/reward";
@import "./component/levelup";
@import "./component/carekit";
@import "./component/bunny";
@import "./component/toast";
@import "./component/wheel";
@import "./component/tooltip";
