.modal-share {
	.modal__container {
		.modal__content {
			@include flexcc;
			.text__head {
				font-size: 0.56rem;
				font-weight: 600;
				color: #ffe841;
				line-height: 0.63rem;
				text-transform: uppercase;
				margin: auto 0 0.38rem;
			}
			.text__desc {
				font-size: 0.19rem;
				color: #fff;
				// color: rgba(255, 255, 255, 0.9);
				line-height: 0.25rem;
				text-align: center;
				width: 80%;
				margin: 0 auto;
			}
			.group {
				margin: 0.4rem 0;
				.btn {
					width: 0.54rem;
					height: 0.54rem;
					margin: 0 0.16rem;
					&.btn-wa {
						@include bgv("ico-wa");
					}
					&.btn-copy {
						@include bgv("ico-copy");
					}
				}
			}
			.btn-grey {
				margin: 0 auto 0.34rem;
				width: 1.85rem;
				height: 0.44rem;
				font-size: 0.24rem;
				line-height: 0.44rem;
				font-weight: 600;
				color: #565c63;
				@include bgv("btn-grey");
			}
		}
	}
}
